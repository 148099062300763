//  =========================================================================================
// File Name: main-menu-mixin.scss
// Description: Common mixin for menus, contain dark and light version scss.
// ----------------------------------------------------------------------------------------
// Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
// Author: PIXINVENT
// Author URL: http://www.themeforest.net/user/pixinvent
//==========================================================================================

// Header navbar content and brand expanded & collapsed stats
// spacing and sizing.
// ================================================

// Mixins for the menu and navbar position, margin and width change
@mixin main-menu-width($menu-width) {
  margin-left: $menu-width;
}
@mixin menu-navbar-width($menu-width) {
  float: left;
  width: $menu-width;
}
// Mixins for the menu show hide based on width
@mixin vertical-menu-transform-show($transform-width) {
  transform: translate3d($transform-width, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;
}
@mixin vertical-menu-transform-hide() {
  transform: translate3d(0, 0, 0);
}

// Mixins for the sub menu arrow
@mixin vertical-menu-has-sub-arrow($arrow-font-size) {
  &.has-sub {
    > a:after {
      content: '';
      background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $body-color), '#', '%23'));
      background-repeat: no-repeat;
      background-position: center;
      background-size: $arrow-font-size;
      height: $arrow-font-size;
      width: $arrow-font-size;
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 14px;
      transform: rotate(0deg);
      transition: all 0.2s ease-out;
    }
    &.open:not(.menu-item-closing) {
      > a:after {
        transform: rotate(90deg);
      }
    }
  }
}
// Used for apps
@mixin content-area-wrapper-height(
  $contentPadding: 0rem,
  $navbarHeight: 0rem,
  $footerHeight: 0rem,
  $floatingNavMargin: 0rem,
  $horizontalMenuHeight: 0rem,
  $spacingSides: 1,
  $horizontalLayout: false
) {
  height: calc(
    100vh -
      calc(
        calc(#{$contentPadding} * #{$spacingSides}) + #{$navbarHeight} + #{$footerHeight} + #{$floatingNavMargin} + #{$horizontalMenuHeight}
      )
  );
  height: calc(
    var(--vh, 1vh) * 100 -
      calc(
        calc(#{$contentPadding} * #{$spacingSides}) + #{$navbarHeight} + #{$footerHeight} + #{$floatingNavMargin} + #{$horizontalMenuHeight}
      )
  );
  // responsive size height
  @if $horizontalLayout {
    @include media-breakpoint-down(lg) {
      height: calc(
        100vh - calc(calc(calc(#{$contentPadding} - 0.8rem) * #{$spacingSides}) + #{$navbarHeight} + #{$footerHeight})
      );
      height: calc(
        var(--vh, 1vh) * 100 -
          calc(calc(calc(#{$contentPadding} - 0.8rem) * #{$spacingSides}) + #{$navbarHeight} + #{$footerHeight})
      );
    }
  } @else {
    @include media-breakpoint-down(xs) {
      height: calc(
        100vh -
          calc(
            calc(calc(#{$contentPadding} * #{$spacingSides}) - calc(0.8rem * #{$spacingSides})) + #{$navbarHeight} + #{$footerHeight} +
              #{$floatingNavMargin} + #{$horizontalMenuHeight}
          )
      );
      height: calc(
        var(--vh, 1vh) * 100 -
          calc(
            calc(calc(#{$contentPadding} * #{$spacingSides}) - calc(0.8rem * #{$spacingSides})) + #{$navbarHeight} + #{$footerHeight} +
              #{$floatingNavMargin} + #{$horizontalMenuHeight}
          )
      );
    }
  }
}

// Menu content display mixin
// $menu-expanded-width : Expanded menu width
// $menu-collapsed-width: Collapsed menu width
// ================================================
@mixin menu-content($menu-expanded-width, $menu-collapsed-width, $theme-color, $theme-bg) {
  .main-menu-content {
    > span.menu-title,
    a.menu-title {
      right: -($menu-expanded-width);
      width: $menu-expanded-width;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      text-align: left;
      background-color: $primary;
      border-color: $primary;
      padding: 13px 20px;
    }
    > ul.menu-content {
      left: $menu-collapsed-width;
      width: $menu-expanded-width;
      transition: visibility 0.25s, opacity 0.25s;
      box-shadow: 1px 0px 2px rgba($black, 0.1);
      border-bottom: 2px solid rgba($black, 0.2);
      border-left: 1px solid rgba($black, 0.02);

      li {
        white-space: nowrap;
        position: relative;

        a {
          display: block;
          padding: 8px 20px 8px 20px;
        }

        @include vertical-menu-has-sub-arrow(1rem);
      }
    }
  }
}
